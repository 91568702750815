import React from 'react'
import styles from './index.module.scss'

const Keyboard = () => (
    <pre className={styles.keyboard}>
        {
            `
                  . -------------------------------------------------------------------.        
                  | [Esc] [F1][F2][F3][F4][F5][F6][F7][F8][F9][F0][F10][F11][F12] o o o|        
                  |                                                                    |        
                  | ['][1][2][3][4][5][6][7][8][9][0][-][=][_<_] [I][H][U] [N][/][*][-]|        
                  | [|-][Q][W][E][R][T][Y][U][I][O][P][{][}] | | [D][E][D] [7][8][9]|+||        
                  | [CAP][A][S][D][F][G][H][J][K][L][;]['][#]|_|           [4][5][6]|_||        
                  | [^][\\][Z][X][C][V][B][N][M][,][.][/] [__^__]    [^]    [1][2][3]| ||        
                  | [c]   [a][________________________][a]   [c] [<][V][>] [ 0  ][.]|_||        
                  '--------------------------------------------------------------------' `
        }
    </pre>
)

export default Keyboard